import React, { useState, useEffect } from 'react';
import PieChart from '../charts/pie-chart';
import BarGraph from '../charts/bar-graph';
import TimeseriesChart from '../charts/line-chart/time-series-chart';
import RenderDynamicTable from './metricskey/render-dynamic-table';
import RealEstateTable from './metricskey/real-estate-table';
import DataTable from './metricskey/data-table';
import StaicRenderTable from './metricskey/data-table-static';
import Select from 'react-select';

import _ from 'lodash';
import './index.less';
import RenderDynamicTablePieFilter from './metricskey/render-dynamic-table-pie-filter';
import { useData, useCities, useSelectedCity, useProperties, useSelectedProperty } from '../../../../context/search-context';
import { toast } from 'react-toastify';
import ToggleButton from '../../../compare-reits/components/toggle-btn';
import FootNotesComponent from './metricskey/foot-note';

const AllMetrics = ({ reitData }) => {
  const displayName = 'cmp-all-metrics';

  const reitName = reitData.name;

  const maxselectYear = _.maxBy(reitData.years, 'year').year;
  const initialYearData = _.find(reitData.years, { year: maxselectYear });

  const [selectedYears, setSelectedYear] = useState([maxselectYear]);
  const [selectedQuarters, setSelectedQuarters] = useState(maxselectYear);
  const [data, setData] = useData();
  const [cities, setCities] = useCities();
  const [selectedCity, setSelectedCity] = useSelectedCity();
  const [properties, setProperties] = useProperties();
  const [selectedProperty, setSelectedProperty] = useSelectedProperty();
  const [isYearly, setIsYearly] = useState(true);


  const _reitData = (() => {
    if (_.isEmpty(reitData?.years)) {
      return {
        ...reitData,
        years: []
      };
    }

    let maxYear = _.maxBy(reitData.years, 'year').year;

    const yearsToUse = (!_.isEmpty(selectedYears)) ? selectedYears : [maxYear];
    return {
      ...reitData,
      years: _.filter(reitData.years, element => _.includes(yearsToUse, element.year.toString()))
    };
  })();

  const _reitYearlyData = _.get(_reitData, 'years', []);

  const _reitQuaterData = (() => {
    if (_.isEmpty(reitData?.years)) {
      return {
        ...reitData,
        years: []
      };
    }

    const yearsToUse = selectedQuarters || _.maxBy(reitData.years, 'year').year;
    return {
      ...reitData,
      years: _.filter(reitData.years, element => element.year.toString() === yearsToUse)
    };
  })();

  const _reitQatData = _.get(_reitQuaterData, 'years', []);

  const extractedQuartersData = _reitQatData.reduce((acc, yearData) => {
    const quarters = _.get(yearData, 'quarters', []);
    return acc.concat(quarters);
  }, []);
  let _reitYearData = _reitYearlyData;
  isYearly === true ? _reitYearData = _reitYearlyData : _reitYearData = extractedQuartersData

  const extractFinancialData = (reitYearData, key) => {
    return _.map(reitYearData, item => ({
      year: isYearly === true ? "FY " + item.year : item.quarter.split(" ").join(" FY "),
      financialData: item[key]
    }));
  };

  const _financialData = extractFinancialData(_reitYearData, 'financialData');
  const _financialMetrics = extractFinancialData(_reitYearData, 'financialMetrics');
  const _unitholderData = extractFinancialData(_reitYearData, 'unitHolderData');
  const _debtProfile = extractFinancialData(_reitYearData, 'debtProfile');
  const _performanceBenchmark = extractFinancialData(_reitYearData, 'performanceBenchmark');
  const _portfolioSummary = extractFinancialData(_reitYearData, 'portfolioSummary');
  const _grossAssetOccupancy = extractFinancialData(_reitYearData, 'grossAssetOccupancy');
  const _grossAssetValueByGeography = extractFinancialData(_reitYearData, 'grossAssetValueByGeography');

  const dimensions = { width: 850, height: 300 };
  const dimensions22 = { width: 250, height: 250 };

  const convertData = (data) => {
    return _.map(data, entry => ({
      year: isYearly === true ? "FY " + entry.year : entry.quarter.split(" ").join(" FY "),
      debtMaturityProfile: _.filter(
        _.map(_.get(entry, 'debtMaturityProfile.debtMaturityProfile', []), item => ({
          name: _.get(item, 'year.value', ''),
          value: parseFloat(_.replace(_.get(item, 'percentage.value', ''), '%', ''))
        })),
        item => item.value !== null && item.value !== undefined && !_.includes(item.value.toString(), '-') && !isNaN(item.value)
      )
    }));
  };

  const yearOptions = _.orderBy(reitData.years, ['year'], ['asc']).map(item => ({ value: item.year, label: "FY " + item.year.toString() }));

  const handleFilterChange = (selectedOption) => {
    if (_.size(selectedOption) >= 1) {
      if (_.size(selectedOption) > 3) {
        toast.warn('You can select no more than 3 Years.', { position: "bottom-right", });
        return
      }
      else {
        const selectedValues = _.map(selectedOption, 'value');
        setSelectedYear(selectedValues);
      }
    } else {
      toast.warn('At least one year needs to be selected', { position: "bottom-right", });
    }
  };

  const handleFilterQuartersChange = (selectedOption) => {
    setSelectedQuarters(selectedOption.value)
  }

  const getUnitholderPieData = (yearData) => {
    const pieData = _.map(
      _.filter(
        _.toPairs(yearData.unitHolderData || {}),
        ([key]) => key !== 'unitHolders'
      ),
      ([key, value]) => {
        const parsedValue = parseFloat(_.replace(_.replace(value.value, '%', ''), /,/g, ''));
        return {
          label: `${value.key} `,
          value: _.isNaN(parsedValue) ? value.value : parsedValue,
        };
      }
    );
    return pieData;
  };

  const getUnitholderPieDataLabel = (yearData, title) => {
    const unitholders = _.find(yearData.unitHolderData, { key: "Unitholders" });
    let _data = "";
    if (title.startsWith('FY')) {
      _data = title + yearData.year;
    } else if (title.startsWith('Q')) {
      _data = yearData.quarter.split(" ").join(" FY ");
    }
    return [unitholders.key, unitholders.value, _data];
  };

  const getProfileSummaryPieData = (yearData) => {
    const pieData = _.map(
      _.filter(
        _.toPairs(yearData.portfolioSummary || {}),
        ([key]) => key !== 'portfolioSize'
      ),
      ([key, value]) => {
        if (value && value.value && value.value !== " - ") {
          let totalValue = 0;

          if (typeof value.value === 'string' && value.value.includes('msf')) {
            const parts = value.value.split(',').map(part => part.trim());
            let filteredParts = parts;

            if (value.value.includes('Hotel')) {
              filteredParts = parts.filter(item => !item.includes("Hotel"));
            }

            totalValue = filteredParts.reduce((sum, part) => {
              const number = parseFloat(part.split(' ')[0]);
              return sum + (isNaN(number) ? 0 : number);
            }, 0);
          } else {
            totalValue = parseFloat(value.value);
          }
          return {
            label: `${value.key} `,
            value: parseFloat(totalValue).toFixed(1),
          };
        }
      }
    ).filter(Boolean); // Filter out undefined values

    return pieData;
  };


  const getProfileSummaryPieDataLabel = (yearData, title) => {
    const unitholders = _.find(yearData.portfolioSummary, { key: "Portfolio Size (msf)" });
    let _data = "";
    if (title.startsWith('FY')) {
      _data = title + yearData.year;
    } else if (title.startsWith('Q')) {
      _data = yearData.quarter.split(" ").join(" FY ");
    }
    return [unitholders.key, unitholders.value.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 1 }), _data];
  };

  const [unitholderpieLabel, setUnitholderpieLabel] = useState(getUnitholderPieDataLabel(initialYearData, "FY "));
  const [unitholderpie, setUnitholderpie] = useState(getUnitholderPieData(initialYearData));

  const [profileSummaryPieLabel, setProfileSummaryPieLabel] = useState(getProfileSummaryPieDataLabel(initialYearData, "FY "));
  const [profileSummaryPie, setProfileSummaryPie] = useState(getProfileSummaryPieData(initialYearData))

  const handleYearSelect = (value) => {

    let _yearData;
    /*    Check if the value is a financial year  */
    if (value.startsWith('FY')) {
      const year = value.split(' ')[1];
      _yearData = _.find(reitData.years, { year });
      if (_yearData) {
        setProfileSummaryPie(getProfileSummaryPieData(_yearData));
        setUnitholderpieLabel(getUnitholderPieDataLabel(_yearData, "FY "))
        setUnitholderpie(getUnitholderPieData(_yearData));
        setProfileSummaryPieLabel(getProfileSummaryPieDataLabel(_yearData, "FY "));
      }
    }

    /*    Check if the value is a fiscal quarters  */
    else if (value.startsWith('Q')) {
      value = value.split(" FY ").join(" ");
      const [quarter, year] = value.split(' ');
      _yearData = _.find(reitData.years, { year });

      if (_yearData) {
        const quarterData = _.find(_yearData.quarters, { quarter: value });
        if (quarterData) {
          setProfileSummaryPie(getProfileSummaryPieData(quarterData));
          setUnitholderpieLabel(getUnitholderPieDataLabel(quarterData, "Q"))
          setUnitholderpie(getUnitholderPieData(quarterData));
          setProfileSummaryPieLabel(getProfileSummaryPieDataLabel(quarterData, "Q"));
        }
      }
    }
  };

  /* Updating SearchBar Dropwdown by Particular Reit */
  const transformData = (data) => {
    const groupedData = _.groupBy(data, (item) => item.location.value);
    const transformedData = _.map(groupedData, (value, key) => ({
      location: key,
      cities: value.filter(item => item.placeName && item.placeName.value).map((item) => ({
        id: _.uniqueId(),
        name: item.placeName.value.toString(),
      })),
    }));

    const locations = _.map(_.uniqBy(data, 'location.value'), (item) => ({
      label: item.location.value.toString(),
      value: item.location.value.toString(),
    }));

    return { locations, transformedData };
  };

  useEffect(() => {
    // setSelectedCity(null);
    //setProperties([]);
    // setSelectedProperty(null);

    const { locations, transformedData } = transformData(_reitData.realEstateLocData);
    const selectedLocation = _.get(locations, '[0]');
    const selectedProperty = _.find(transformedData, { location: selectedLocation.value });
    const firstCity = selectedProperty ? { label: _.get(selectedProperty, 'cities[0].name'), value: _.get(selectedProperty, 'cities[0].name') } : null;

    //setCities(locations);
    //setData(transformedData);

    setSelectedCity(selectedLocation);
    setSelectedProperty(firstCity)

  }, [_reitData.realEstateLocData, setCities, setData, setProperties, setSelectedCity, setSelectedProperty]);
  /* Updating SearchBar Dropwdown by Particular Reit */

  const handleToggle = (value) => {
    setIsYearly(value);
    if (value) {
      setSelectedQuarters()
      setSelectedYear([maxselectYear])
    }
    else {
      setSelectedQuarters(maxselectYear)
      setSelectedYear([maxselectYear])
    }
  };

  const renderFilter = (label, isMulti, onChange, selectedValues) => (
    <div className={`${displayName}__filter-container`}>
      <div className={`${displayName}__filter-text`}>
        {label}
      </div>
      <div className={`${displayName}__filter-main`}>
        <Select
          options={yearOptions}
          isMulti={isMulti}
          onChange={onChange}
          value={yearOptions.filter(option => selectedValues.includes(option.value))}
          className="cmp-all-metrics__basic_multi_select"
        />
      </div>
    </div>
  );

  return (
    <div className={`${displayName}`}>

      {/* Market Performance */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Market Performance
          </span>
        </div>
        <div className={`${displayName}_section__graph`}>
          <TimeseriesChart marketPrice={_reitData.historicalDataSets[0].marketPrice} dimensions={dimensions} />
        </div>
        <div className={`${displayName}_section__tbl`}> {/* <div style={{ marginTop: "-25px" }}> */}
          <DataTable props={_.get(_reitData, 'years', [])[0].marketPositioning} />
        </div>
      </div>

      {/* Filter */}
      <div>
        <div className={`${displayName}__filter-header`}>
          {isYearly
            ? renderFilter('Financial Year', true, handleFilterChange, selectedYears)
            : renderFilter('Fiscal Quarters', false, handleFilterQuartersChange, selectedQuarters)
          }
          <ToggleButton onToggle={handleToggle} />
        </div>
      </div>

      {/* Key Financial Performance */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Key Financial Performance
          </span>
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTable data={_financialData} />
        </div>
      </div>

      {/* Operational Metrics */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Operational Metrics
          </span>
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTable data={_financialMetrics} />
        </div>
        <div className={`${displayName}_section__foonote`}>
          Note: This data is disclosed half-yearly.
        </div>
      </div>

      {/* Unitholding Pattern */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Unitholding Pattern
          </span>
        </div>
        <div className={`${displayName}_section__graph`}>
          <PieChart data={unitholderpie} dimensions={dimensions22} label={unitholderpieLabel} />
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTablePieFilter data={_unitholderData} onYearSelect={handleYearSelect} />
        </div>
      </div>

      {/* Debt Profile */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Debt Profile
          </span>
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTable data={_debtProfile} />
        </div>
      </div>

      {/* Debt Maturity Profile */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Debt Maturity Profile
          </span>
        </div>
        <div className={`${displayName}_section__graph` } style={{}}>
          <BarGraph data={convertData(_reitYearData)} />
        </div>
        <div className={`${displayName}_section__tbl`} style={{ overflowX: "auto" }}>
          <StaicRenderTable data={convertData(_reitYearData)} />
        </div>
        <div className={`${displayName}_section__footnote`}>
          <FootNotesComponent
            name={reitName}
            tableFor="Debt Maturity Profile"
            selectedYears={isYearly ? selectedYears : selectedQuarters}
            isYearly={isYearly}
          />
        </div>
      </div>

      {/* Performance Benchmark */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Performance Benchmark
          </span>
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTable data={_performanceBenchmark} />
        </div>
      </div>

      {/* Portfolio Summary */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Portfolio Summary
          </span>
        </div>
        <div className={`${displayName}_section__graph`}>
          <PieChart data={profileSummaryPie} dimensions={dimensions22} label={profileSummaryPieLabel} />
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTablePieFilter data={_portfolioSummary} onYearSelect={handleYearSelect} />
        </div>
      </div>

      {/* Portfolio Details */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Portfolio Details
          </span>
        </div>
        <div className={`${displayName}_section__tbl`} style={{ overflowX: "auto" }}>
          <RealEstateTable realEstateData={_reitData.realEstateLocData} />
        </div>
      </div>

      {/* Occupancy */}
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Occupancy
          </span>
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTable data={_grossAssetOccupancy} />
        </div>
      </div>

      {/* Gross Asset Value by Geography */}
      {/*
      <div className={`${displayName}_section`}>
        <div className={`${displayName}_section__title`}>
          <span className={`${displayName}_section__title_name`}>
            Gross Asset Value by Geography
          </span>
        </div>
        <div className={`${displayName}_section__tbl`}>
          <RenderDynamicTable data={_grossAssetValueByGeography} />
        </div>
      </div>
        */}

    </div>
  )
}

export default AllMetrics;