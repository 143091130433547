import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ retoken }) => {
  const onReCAPTCHAChange = (token) => {
    retoken(token);
    console.log(token);
  };
  return (
    <>
      <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} onChange={onReCAPTCHAChange} action='submit' />
    </>
  );
};

export default Captcha;
