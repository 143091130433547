import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './bar-graph.less';

const BarGraph = ({ data }) => {
  const ref = useRef();
  const tooltipRef = useRef();

  const roundedRect = (x, y, w, h, r, tl, tr, bl, br) => {
    let retval;
    retval = `M${x + r},${y}`;
    retval += `h${w - (2 * r)}`;
    if (tr) {
      retval += `a${r},${r} 0 0 1 ${r},${r}`;
    } else {
      retval += `h${r}`; retval += `v${r}`;
    }
    retval += `v${h - (2 * r)}`;
    if (br) {
      retval += `a${r},${r} 0 0 1 ${-r},${r}`;
    } else {
      retval += `v${r}`; retval += `h${-r}`;
    }
    retval += `h${(2 * r) - w}`;
    if (bl) {
      retval += `a${r},${r} 0 0 1 ${-r},${-r}`;
    } else {
      retval += `h${-r}`; retval += `v${-r}`;
    }
    retval += `v${((2 * r) - h)}`;
    if (tl) {
      retval += `a${r},${r} 0 0 1 ${r},${-r}`;
    } else {
      retval += `v${-r}`; retval += `h${r}`;
    }
    retval += 'z';
    return retval;
  }

  useEffect(() => {
    if (data.length === 0) return; // Ensure data is not empty

    const svg = d3.select(ref.current);
    svg.selectAll("*").remove(); // Clear SVG before redrawing

    const margin = { top: 20, right: 30, bottom: 40, left: 100 };
    const width = 929 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;
    const allNames = Array.from(new Set(data.flatMap(d => d.debtMaturityProfile.map(p => p.name)))).sort();
    const allYears = data.map(d => d.year);
    const maxValue = d3.max(data.flatMap(d => d.debtMaturityProfile.map(p => p.value)));

    const customColors = [
      "#0FDCD2",
      "#14324E",
      "#A5A5A5",
      "#416D91",
      "#5B9AD5",
    ];

    const graph = svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Y axis
    const y = d3.scaleLinear()
      .domain([0, maxValue])
      .range([height, 0]);

    graph.append("g") 
      .call(d3.axisLeft(y).tickFormat(d => `${d}`))
      .call(g => g.selectAll(".tick line").remove())
      .call(g => g.select(".domain").remove());

    // X axis
    const x = d3.scaleBand()
      .range([0, 600])
      .domain(allNames)
      .padding(0.2);

    graph.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll(".tick text")
      .attr("dy", "1em");

    graph.selectAll("g.tick")
      .select("line.grid-line")
      .data(y.ticks())
      .join("line")
      .attr("class", "grid-line")
      .attr("x1", 0)
      .attr("x2", 600)
      .attr("y1", d => y(d))
      .attr("y2", d => y(d))
      .attr("stroke", "grey")
      .attr("stroke-dasharray", "0");

    // Custom color scale
    const color = d3.scaleOrdinal()
      .domain(allYears)
      .range(customColors);

    // Draw bars
    data.forEach(d => {
      const sanitizedYear = d.year.replace(/\s+/g, '-'); // Replace spaces with dashes

      graph.selectAll(`.bar-${sanitizedYear}`)
        .data(d.debtMaturityProfile)
        .enter()
        .append("path")
        .attr("class", `bar-${sanitizedYear}`)
        .attr("d", p => {
          const barWidth = x.bandwidth() / data.length;
          return roundedRect(
            x(p.name) + barWidth * allYears.indexOf(d.year),
            y(p.value),
            barWidth,
            height - y(p.value),
            5, true, true, false, false
          );
        })
        .attr("fill", color(d.year))
        .on("mouseover", function (event, p) {
          d3.select(tooltipRef.current)
            .style("visibility", "visible")
            .text(`${d.year}: ${p.value}`)
            .style("left", `${event.pageX}px`)
            .style("top", `${event.pageY - 28}px`)
            .style("background-color", color(d.year))
            .style("font-family", "Gotham-Medium");
        })
        .on("mouseout", () => {
          d3.select(tooltipRef.current).style("visibility", "hidden");
        });
    });

    // Add legend
    const legend = svg.append("g")
      .attr("transform", `translate(${width - 100}, ${margin.top})`);

    legend.selectAll("rect")
      .data(allYears)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("y", (d, i) => i * 20)
      .attr("width", 14)
      .attr("height", 14)
      .attr("rx", 8)
      .style("fill", d => color(d));

    legend.selectAll("text")
      .data(allYears)
      .enter()
      .append("text")
      .attr("x", 24)
      .attr("y", (d, i) => i * 20 + 9)
      .attr("dy", "0.35em")
      .text(d => d);

  }, [data]);

  return (
    <>
      <svg ref={ref}></svg>
      <div ref={tooltipRef} className="tooltip"></div>
    </>
  );
};

export default BarGraph;