import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useCities, useSelectedCity, useProperties, useSelectedProperty, useData } from '../../../../context/search-context';
import _ from 'lodash';
import { toast } from 'react-toastify';
import service from '../../../../services/service';

import './index.less';

const DynamicDropdownRow = ({ onData }) => {
    const [data, setData] = useData();
    const [cities, setCities] = useCities();
    const [selectedCity, setSelectedCity] = useSelectedCity();

    const [properties, setProperties] = useProperties();
    const [selectedProperty, setSelectedProperty] = useSelectedProperty();

    const [rows, setRows] = useState(() => {
        const savedRows = localStorage.getItem('dynamicDropdownRows');
        return savedRows ? JSON.parse(savedRows) : [{ cities: selectedCity, properties: selectedProperty, propertiesOptions: properties }];
    });

    useEffect(() => {

        const fetchData = async () => {
          try {
            const response = await service.fetchCities();
            if (_.isEmpty(response)) {
              throw new Error('HTTP error! status: No response');
            }
            setData(response);
            const cityOptions = response.map((location) => ({
              value: location.location,
              label: location.location,
            })).sort((a, b) => a.label.localeCompare(b.label));
            setCities(cityOptions);
          } catch (error) {
            console.error('Failed to fetch data:', error);
          }
        };
    
        fetchData();
      }, [setData, setCities, location]);

      useEffect(() => {
        if (selectedCity) localStorage.setItem('selectedCity', JSON.stringify(selectedCity));
        if (selectedProperty) localStorage.setItem('selectedProperty', JSON.stringify(selectedProperty));
    }, [selectedCity, selectedProperty]);

    useEffect(() => {
        localStorage.setItem('dynamicDropdownRows', JSON.stringify(rows));
    }, [rows]);

    const getSelectedPropertyValues = (rows) => {
        return rows.map(row => row.properties.value).filter(value => value);
    };

    useEffect(() => {
        const savedCity = localStorage.getItem('selectedCity');
        
        if (savedCity) setSelectedCity(JSON.parse(savedCity));

        if (selectedProperty && selectedProperty.value) {
            onData([selectedProperty.value]);
        }
    }, []);

    useEffect(() => {
        if (selectedProperty && selectedProperty.value) {
            onData([selectedProperty.value]);
        }
    }, []);

    const customStyles = { menu: base => ({ ...base, marginTop: 0,position: 'absolute', zIndex: 99 }) };

    const handleInputChange = (type, e, index) => {
        const values = [...rows];
        values[index][type] = e;
        setRows(values);
        onData(getSelectedPropertyValues(values));
        if (type === 'properties') {
            setSelectedProperty(e);
        }
    };

    const handleAddRow = () => {
        if (rows.length < 4) {
            const newRows = [...rows, { cities: '', properties: '', propertiesOptions: [] }];
            setRows(newRows);
            onData(getSelectedPropertyValues(newRows));
        } else {
            toast.warn("The maximum number of Properties can be that can be selected is 4.");
        }
    };

    const handleRemoveRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
        onData(getSelectedPropertyValues(newRows));
    };

    const handleCityChange = (selectedOption, index) => {
        const values = [...rows];
        const selectedLocation = _.find(data, { location: selectedOption.value });

        const propertyOptions = selectedLocation.cities
            .map((i) => ({
                value: i.name,
                label: i.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        values[index].cities = selectedOption;
        values[index].propertiesOptions = propertyOptions;
        values[index].properties = '';

        setRows(values);
    };

    return (
        <table className="dynamic-dropdown-table">
            <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <Select
                                options={cities}
                                value={row.cities}
                                onChange={(e) => handleCityChange(e, index)}
                                placeholder="Select City"
                                styles={customStyles}
                            />
                        </td>
                        <td>
                            <Select
                                name="properties"
                                options={row.propertiesOptions}
                                onChange={(e) => handleInputChange('properties', e, index)}
                                value={row.properties}
                                isDisabled={!row.cities}
                                placeholder="Select Property"
                                styles={customStyles}
                            />
                        </td>
                        <td>
                            {index !== 0 ? (
                                <button
                                    className="remove-row-btn"
                                    onClick={() => handleRemoveRow(index)}
                                >
                                    -
                                </button>
                            ) : (
                                <button
                                    className="add-row-btn"
                                    onClick={handleAddRow}
                                >
                                    +
                                </button>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DynamicDropdownRow;